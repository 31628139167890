<template>
  <div class="eu-flex-item eu-align_right">
    <div class="emd-clear-select">
      <select v-on:change="selectOrdination">
        <option
          v-for="(item, index) in ordenationOptions"
          :value="JSON.stringify(item.value)"
          :key="index"
          :selected="item.selected"
        >
          {{item.label}}
        </option>
      </select>
      <i class="uil uil-angle-down" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppOrdinationSelect',

  data () {
    return {
      ordenationOptions: [
        {
          label: 'Mais relevantes',
          selected: (!this.$route.query.sort_field && !this.$route.query.sort_mode) || false,
          value: {}
        },
        {
          label: 'Mais recentes',
          selected: this.validateActiveQueryParmams('created_at', 'desc') || false,
          value: {
            sort_field: 'created_at',
            sort_mode: 'desc'
          }
        },
        {
          label: 'Menor preço',
          selected: this.validateActiveQueryParmams('price', 'asc') || false,
          value: {
            sort_field: 'price',
            sort_mode: 'asc'
          }
        },
        {
          label: 'Maior preço',
          selected: this.validateActiveQueryParmams('price', 'desc') || false,
          value: {
            sort_field: 'price',
            sort_mode: 'desc'
          }
        }
      ]
    }
  },

  methods: {
    selectOrdination (e) {
      const value = JSON.parse(e.target.value)
      this.$store.dispatch('SET_FILTER_PARAMS', value)
    },
    validateActiveQueryParmams (sortField, sortMode) {
      return this.$route.query.sort_field === sortField && this.$route.query.sort_mode === sortMode
    }
  }
}
</script>
