<template>
  <div class="eu-mb_4 eu-mt_5_mobile eu-width_100">
    <div
      class="eu-flex eu-justify_space-between eu-align-items_center"
      :class="{'_simple': simple}"
    >
      <div class="eu-flex-item eu-basis_12_mobile eu-align_center eu-align_left_sm">
        <span
          class="emd-text _color_subtle _size_small"
        >{{!simple ? 'Página' : ''}} {{currentPage}} {{ 'de' }} {{totalPage}} {{!simple ? '' : ''}}</span>
      </div>
      <div class="eu-flex-item eu-align_center eu-align_right_sm eu-mt_4 eu-no-margin_sm">
        <button
          class="emd-btn _color_primary _type_text"
          :disabled="currentPage === 1"
          @click.prevent="goToPage(currentPage - 1)"
        >Anterior</button>
        <button
          class="emd-btn _color_primary _type_text"
          :disabled="currentPage === totalPage || totalItems === 0"
          @click.prevent="goToPage(currentPage + 1)"
        >Próximo</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppPagination',
  props: {
    data: {
      type: [Object, Boolean],
      required: true
    },
    action: {
      type: [Object, Boolean],
      required: true
    },
    simple: {
      type: Boolean,
      default: false
    },
    customSize: {
      type: Number
    },
    searchTerm: {
      type: String
    }
  },
  computed: {
    totalItems () {
      if (this.data.paging) {
        return this.data ? this.data.paging.total_items : 1
      } else {
        return this.data ? this.data.pagination.total_items : 1
      }
    },
    currentPage () {
      if (this.data.paging) {
        return this.data ? this.data.paging.page : 1
      } else {
        return this.data ? this.data.pagination.current_page : 1
      }
    },
    totalPage () {
      if (this.data.paging) {
        return this.data ? this.data.paging.total_pages : 1
      } else {
        return this.data ? this.data.pagination.total_page : 1
      }
    },
    pageItems () {
      return this.data ? this.data.items.length : 1
    }
  },
  methods: {
    // refreshQueryUrl: function (query) {
    //   this.$router.replace({ query: query }).catch(err => err)
    // },
    goToPage: function (page) {
      const queryString = {}

      if (this.data.paging) queryString.page = page
      else queryString.page = page

      if (this.customSize) queryString.size = this.customSize
      if (this.searchTerm) queryString.identifier = this.searchTerm
      this.$store.dispatch(this.action.name, queryString)
      // this.refreshQueryUrl(queryString)
    }
  }
}
</script>
