import { env } from '@/store'

const cart = {
  state: {
    list: [],
    totalAmount: 0,
    tooltipIsOpen: false
  },
  getters: {
    cart (state) {
      return state.list
    },
    totalAmount (state) {
      return state.totalAmount
    },
    tooltipIsOpen (state) {
      return state.tooltipIsOpen
    }
  },
  mutations: {
    setCart (state, value) {
      state.list = value
    },
    updateItemQuantity (state, value) {
      state.list[value.index] = Object.assign(state.list[value.index], { quantity: value.quantity })
    },
    addCartItem (state, value) {
      state.list.push(value)
    },
    removeCartItem (state, value) {
      let idToRemove = null
      state.list.forEach((item, id) => {
        if (item.id === value) idToRemove = id
      })
      state.list.splice(idToRemove, 1)
    },
    updateTotalAmount (state, value) {
      state.totalAmount = value
    },
    setTooltipIsOpen (state, value) {
      state.tooltipIsOpen = value
    }
  },
  actions: {
    'GET_CART' (context) {
      let local = localStorage.getItem(`${env}cartData`)
      local = local ? JSON.parse(atob(local)) : {}
      if (local && local.storeId === context.rootState.storeData.id) {
        context.commit('setCart', local.cart)
        context.dispatch('UPDATE_TOTAL_AMOUNT')
      } else {
        localStorage.removeItem(`${env}cartData`)
      }
      context.commit('toggleGlobalLoading', false)
    },
    'ADD_ITEM_CART' (context, data) {
      let idItemExists = false
      context.state.list.forEach((item, id) => {
        if (item.id === data.id) idItemExists = id
      })
      if (typeof idItemExists === 'number') {
        const value = {
          index: idItemExists,
          quantity: context.state.list[idItemExists].quantity + 1
        }
        context.commit('updateItemQuantity', value)
      } else {
        context.commit('addCartItem', data)
      }
      context.dispatch('UPDATE_LOCALSTORAGE')
      context.dispatch('UPDATE_TOTAL_AMOUNT')
    },
    'UPDATE_QUANTITY_CART' (context, data) {
      let idToUpdate = false
      context.state.list.forEach((item, index) => {
        if (item.id === data.id) idToUpdate = index
      })
      const value = {
        index: idToUpdate,
        quantity: data.quantity
      }
      if (typeof idToUpdate === 'number') {
        context.commit('updateItemQuantity', value)
      }
      context.dispatch('UPDATE_LOCALSTORAGE')
      context.dispatch('UPDATE_TOTAL_AMOUNT')
    },
    'REMOVE_CART_ITEM' (context, itemId) {
      context.commit('removeCartItem', itemId)
      context.dispatch('UPDATE_LOCALSTORAGE')
      context.dispatch('UPDATE_TOTAL_AMOUNT')
    },
    'UPDATE_LOCALSTORAGE' (context) {
      const toLocalStorage = {
        storeId: context.rootState.storeData.id,
        cart: context.state.list
      }
      localStorage.setItem(`${env}cartData`, btoa(JSON.stringify(toLocalStorage)))
    },
    'UPDATE_TOTAL_AMOUNT' (context) {
      let totalAmount = 0
      context.state.list.forEach(item => {
        totalAmount = totalAmount + (item.quantity * item.price)
      })
      context.commit('updateTotalAmount', totalAmount)
      context.dispatch('VERIFY_SHIPPING_ENABLED')
    },
    'UPDATE_TOOLTIP_IS_OPEN' (context, value) {
      context.commit('setTooltipIsOpen', value)
    }
  }
}

export default cart
