<template>
  <section class="el-column">
    <div class="el-bounds _narrow">
      <div class="emd-text _align_center">
        <error-404 v-if="code === 404"/>
        <error-500 v-else @ctaClicked="refreshPage"/>
      </div>
    </div>
  </section>
</template>
<script>
import Error404 from './Error404'
import Error500 from './Error500'

export default {
  name: 'GlobalError',
  components: {
    Error404,
    Error500
  },
  computed: {
    code () {
      return this.$store.state.globalErrorCode
    }
  },
  methods: {
    refreshPage () {
      this.$router.go()
    }
  }
}
</script>
