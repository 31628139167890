<template>
  <div>
    <template v-if="shipping">
      <div class="el-chunk">
        <h3 class="emd-text _size_section">
          {{ showShippingAddress ? 'Endereço de Entrega' : 'Nome do comprador' }}
        </h3>
      </div>
      <div class="el-chunk">
        <div class="el-grid-list _auto-fill">
          <div class="emd-container _type_card">
            <div class="emd-container__content">
              <div class="el-chunk">
                <h2 class="emd-text _type_caption">{{ shipping.name.value }}</h2>
              </div>
              <p class="emd-text _size_small" v-if="showShippingAddress">{{ shipping.zip_code.value | CEP }}</p>
              <p class="emd-text _size_small" v-if="showShippingAddress">{{ shipping.street.value }} {{ shipping.number.value }}<span v-if="shipping.complement.value">, {{ shipping.complement.value }}</span> - {{ shipping.neighborhood.value }}</p>
              <p class="emd-text _size_small" v-if="showShippingAddress">{{ shipping.city.value }} - {{ shipping.state.value }} - {{ shipping.country.value }}</p>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="items">
      <div class="el-chunk">
        <h3 class="emd-text _size_section">
          Produtos
        </h3>
      </div>
      <div class="el-grid-list _auto-fill">
        <div v-for="(item, index) in items" :key="index" class="emd-container _type_card eu-display_flex">
          <div class="emd-image-table">
            <img v-if="item.display_image" :src="item.display_image" :alt="item.name" :title="item.name" :id="item.id+'_item'" @error="onImgError(item)">
            <img v-else :src="empty_bag" :alt="item.name" :title="item.name" />
          </div>
          <div class="emd-container__content eu-grow_1">
            <div>
              <p class="emd-text eu-pb_1">
                {{ item.name }}
              </p>
              <p class="emd-text _color_subtle _size_tiny eu-pb_3">
                {{ item.description }}
              </p>
            </div>
            <div class="eu-align_right">
              <p class="eu-ws_nowrap emd-text _color_primary _size_small _type_caption">
                {{ item.quantity }} x R$ <span class="emd-text _size_section">{{ item.price | currency }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'AppCheckout',
  data () {
    return {
      empty_bag: `${process.env.VUE_APP_CDN_URL}/assets/images/storefront/empty-bag-sm.svg`
    }
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: null
    },
    shipping: {
      type: Object,
      default: null
    }
  },
  computed: {
    showShippingAddress () {
      return this.$store.getters.showShippingAddress
    }
  },
  methods: {
    onImgError (item) {
      const imgTag = document.getElementById(`${item.id}_item`)
      imgTag.src = this.empty_bag
    }
  }
}
</script>
