export const isEmpty = (text) => {
  return (text === '' || text === null)
}

export function hasError (field) {
  var error = false

  if (!field.validation) return false

  if (field.validation.required && isEmpty(field.value)) {
    return 'required'
  }

  if (!isEmpty(field.value)) {
    if (field.validation.length) {
      const str = field.value.toString()
      if (typeof field.validation.length === 'object') {
        error = true
        field.validation.length.forEach(size => {
          if (str.length === size) {
            error = false
          }
        })
        return error ? 'length' : false
      }
      return str.length !== field.validation.length ? 'length' : false
    }
  }

  return false
}
