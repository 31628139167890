<template>
  <div class="eu-flex-item">
    <div class="emd-compact-input" :class="{ '_active': searchIsOpenMobile }">
      <button class="emd-btn _type_link eu-shrink_1 eu-mr_2" v-on:click="openSearch">
        <span class="emd-text _size_subpage">
          <i class="uil uil-search" />
        </span>
      </button>
      <input id="inputSearch" type="text" class="emd-clear-input" v-model="identifier" v-on:input="searchProd(identifier)" />
    </div>
  </div>
</template>
<script>
import EventBus from '@/event-bus'
export default {
  name: 'AppInputSearch',
  data () {
    return {
      identifier: '',
      debounceTimer: undefined,
      searchIsOpenMobile: false
    }
  },
  methods: {
    searchProd (identifier) {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        if (!identifier.length) this.$store.dispatch('SET_FILTER_PARAMS', { keywords: null })
        else this.$store.dispatch('SET_FILTER_PARAMS', { keywords: identifier })
      }, 850)
    },
    openSearch () {
      if (window.innerWidth < 544) this.searchIsOpenMobile = !this.searchIsOpenMobile
    }
  },
  beforeMount () {
    if (this.$route.query.keywords) {
      this.identifier = this.$route.query.keywords
    }
  },
  created () {
    EventBus.$on('CLEAN_INPUT_SEARCH', payLoad => {
      if (payLoad) document.querySelector('input#inputSearch').value = ''
    })
  }
}
</script>
