import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import store from '@/store'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/:slug',
      name: 'home',
      component: Home,
      beforeEnter: (to, from, next) => {
        const slug = to.params.slug
        if (slug) {
          store.dispatch('GET_STORE_DATA', { slug: slug })
            .then(res => {
              store.dispatch('GET_PRODUCTS', to.query)
              next()
            })
            .catch(_ => {
              next()
            })
        } else {
          store.dispatch('toggleGlobalError', { status: true, code: 404 })
        }
      }
    },
    {
      path: '',
      redirect: { name: 'home' }
    }
  ]
})
