import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import VueGtag from 'vue-gtag'
import VueTheMask from 'vue-the-mask'
import getGoogleAnalyticsSettings from './utils/googleAnalytics'

import { formatCurrency, formatPhone, formatCEP } from './filters'

// FONT AWESOME CONFIG & IMPORTS
import './plugins/click-outside'

// IMPORT EMERALD GLOBALY
import 'emerald-workbench/dist/emerald.stone.min.css'

Vue.config.productionTip = false

Vue.use(VueTheMask)

Vue.filter('currency', formatCurrency)
Vue.filter('phone', formatPhone)
Vue.filter('CEP', formatCEP)

if (['production'].includes(process.env.NODE_ENV)) {
  const googleAnalyticsSettings = getGoogleAnalyticsSettings()
  Vue.use(VueGtag, googleAnalyticsSettings, router)
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
