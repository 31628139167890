<template>
  <div class="emd-skeleton-container eu-display_flex eu-row eu-column_sm">
    <div class="emd-image-card eu-pl_2_mobile eu-py_2_mobile">
      <img :src="no_product" alt="Sem imagem">
    </div>
    <div class="eu-pa_3 eu-grow_1 eu-display_flex eu-column">
      <div class="eu-grow_1">
        <p class="emd-skeleton-text _color_lvl_5 eu-mb_1">
        </p>
        <p class="emd-skeleton-text _color_lvl_4 _width_80 _size_tiny eu-mb_3">
        </p>
      </div>
      <div class="eu-flex eu-align-items_center">
        <div class="eu-flex-item eu-align_right eu-shrink_0">
          <div class="emd-skeleton-btn _color_lvl_3"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppCardEmptySpace',
  data () {
    return {
      no_product: `${process.env.VUE_APP_CDN_URL}/assets/images/storefront/no-product.svg`
    }
  }
}
</script>
