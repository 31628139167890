import { StorefrontInstance, catchError } from '@/store'

const checkout = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    'CREATE_CHECKOUT' (context, { storeId, body }) {
      return StorefrontInstance.checkout.create(storeId, body)
        .then((res) => Promise.resolve(res))
        .catch((err) => catchError(err, context))
    }
  }
}

export default checkout
