<template>
  <div class="el-storefront__main__footer">
    <img :src="compre_local" width="140" alt="Compre local">
    <div class="eu-my_4">
      <p class="emd-text _size_section _color_nonessential _type_normal">Fique em casa</p>
      <strong class="emd-text _size_section _color_nonessential eu-mt_3">Compre de um pequeno negócio</strong>
    </div>
    <a href="https://cuidedopequenonegocio.com.br" target="_blank" class="emd-link _color_primary">
      <span class="emd-text _type_label _size_tiny">Saiba mais</span>
    </a>
  </div>
</template>

<script>

export default {
  name: 'AppFooter',
  data () {
    return {
      compre_local: `${process.env.VUE_APP_CDN_URL}/assets/images/logos/stoneco/compre_local.png`
    }
  }
}
</script>
