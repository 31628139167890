export function formatCurrency (text) {
  if (!text) return text
  if (isNaN(text)) return text
  return new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(text / 100)
}

export function formatPhone (text) {
  if (!text) return text
  if (text.length === 11) return `${text.slice(0, 2)} ${text.slice(2, 7)}-${text.slice(7, 11)}`
  if (text.length === 10) return `${text.slice(0, 2)} ${text.slice(2, 6)}-${text.slice(6, 10)}`
  if (text.length === 14) return `${text.slice(3, 5)} ${text.slice(5, 9)}-${text.slice(9, 13)}`
  if (text.length === 15) return `${text.slice(3, 5)} ${text.slice(5, 10)}-${text.slice(10, 14)}`
  return text
}

export function formatCEP (text) {
  if (!text) return text
  if (text.length === 8) return `${text.slice(0, 5)}-${text.slice(5, 8)}`
  return text
}
