<template>
  <div class="eu-flex eu-align-items_center">
    <div class="eu-flex-item eu-shrink_1">
      <div class="emd-image-table eu-pl_2_mobile eu-py_2_mobile eu-pa_3_sm">
        <img v-if="item.display_image" :src="item.display_image" :alt="item.name" :title="item.name" :id="item.id+'_item'" @error="onImgError(item)" />
        <img v-else :src="empty_bag" :alt="item.name" :title="item.name" />
      </div>
    </div>
    <div class="eu-flex-item eu-grow_1">
      <div class="eu-flex eu-align-items_center">
        <div class="eu-flex-item eu-basis_12 eu-basis_8_md">
          <div class="eu-flex eu-align-items_center">
            <div class="eu-flex-item eu-grow_1">
              <p class="emd-text _type_caption eu-pb_1">{{ item.name }}</p>
              <p class="emd-text _size_small _color_subtle">
                Valor unitário: R$ {{ item.price | currency }}
              </p>
            </div>
            <div class="eu-flex-item eu-shrink_1">
              <div class="emd-input-group">
                <div class="emd-btn _type_link _size_small" @click="updateQuantity(quantity - 1)">
                  <i class="uil uil-minus" />
                </div>
                <input type="tel" class="emd-clear-input eu-align_center" v-model="quantity" @input="changed" style="font-size: 16px;"/>
                <div class="emd-btn _type_link _size_small" @click="updateQuantity(quantity + 1)">
                  <i class="uil uil-plus" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="eu-flex-item eu-basis_12 eu-basis_4_md eu-mt_3 eu-no-margin_md">
          <div class="eu-flex eu-align-items_center eu-column_reverse_md">
            <div class="eu-flex-item eu-align_right_md">
              <button class="emd-btn _color_danger _type_link" @click="removeItem">Remover</button>
            </div>
            <div class="eu-flex-item eu-align_right">
              <p class="emd-text _size_section">R$ {{ item.price * item.quantity | currency }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="emd-divider eu-mt_3" />
  </div>
</template>

<script>
export default {
  name: 'EmdListItem',
  props: {
    item: Object
  },
  data () {
    return {
      empty_bag: `${process.env.VUE_APP_CDN_URL}/assets/images/storefront/empty-bag-sm.svg`,
      oldValue: this.item.quantity,
      debounceTimer: undefined
    }
  },
  computed: {
    quantity: {
      get () {
        return this.item.quantity
      },
      set (newValue) {
        if (Number(newValue) >= 1) {
          this.updateQuantity(newValue)
        } else if (isNaN(newValue) || (Number(newValue) < 1 && newValue !== '')) {
          this.updateQuantity(0)
        }
      }
    }
  },
  methods: {
    onImgError (item) {
      const imgTag = document.getElementById(`${item.id}_item`)
      imgTag.src = this.empty_bag
    },
    changed (e) {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        e.target.value = this.quantity
      }, 750)
    },
    updateQuantity (n) {
      this.$emit('updateQuantity', { item: this.item, quantity: Number(n) })
    },
    removeItem () {
      this.$emit('removeItem', this.item)
    }
  }
}
</script>

<style scoped>
.emd-input-group {
  align-items: center;
}
</style>
