import Vue from 'vue'
import Vuex from 'vuex'
import StorefrontCore from 'storefront-js'
import CheckoutCore from 'checkout-js'

// Modules
import cart from './modules/cart'
import theme from './modules/theme'
import products from './modules/products'
import shipping from './modules/shipping'
import checkout from './modules/checkout'

Vue.use(Vuex)

const dataCore = {
  environment: process.env.VUE_APP_CORE_ENV,
  theme: process.env.VUE_APP_THEME
}

export const CheckoutInstance = new CheckoutCore(dataCore)
export const StorefrontInstance = new StorefrontCore(dataCore)

export const env = StorefrontInstance.envAcronym

export const catchError = function (error, context) {
  if (error.status === 404 || error.status === 500) {
    context.commit('toggleGlobalError', { status: true, code: error.status })
  }
  if (window.newrelic) { window.newrelic.noticeError(error.message) }
  return Promise.reject(error)
}

export default new Vuex.Store({
  modules: {
    cart,
    theme,
    products,
    shipping,
    checkout
  },
  state: {
    storeData: null,
    globalLoading: true,
    globalError: false
  },
  getters: {
    storeData (state) {
      return state.storeData ? state.storeData : {}
    },
    globalLoading (state) {
      return state.globalLoading
    },
    environment () {
      return env
    }
  },
  mutations: {
    toggleGlobalLoading (state, value) {
      state.globalLoading = value
    },
    setStoreData (state, value) {
      state.storeData = value
    },
    toggleGlobalError (state, { status, code }) {
      state.globalError = status
      state.globalErrorCode = code
    }
  },
  actions: {
    toggleGlobalError ({ commit }, { status, code }) {
      commit('toggleGlobalError', { status, code })
    },
    'GET_STORE_DATA' (context, params = {}) {
      return StorefrontInstance.store.get(params.slug)
        .then((res) => {
          if (window.newrelic) {
            window.newrelic.interaction().setAttribute('slug', res.slug)
          }
          document.title = res.name
          context.commit('setAccountTheme', res.color)
          context.commit('setStoreData', res)
          context.dispatch('GET_CATEGORIES')
          return Promise.resolve(res)
        })
        .catch((err) => catchError(err, context))
    },
    'GET_ZIPCODE' (context, params = {}) {
      return CheckoutInstance.utils.getAddressByZipCode(params.zipCode)
        .then((res) => {
          if (window.newrelic) {
            window.newrelic.interaction().setAttribute('id', res.slug)
          }
          return Promise.resolve(res)
        })
        .catch((err) => {
          if (window.newrelic) {
            if (err.status === 404) {
              window.newrelic.noticeError(new Error(`CEP ${params.zipCode} não encontrado`))
            } else {
              window.newrelic.noticeError(new Error(`Erro ${err.status} na api de CEP`), err)
            }
          }
          return Promise.reject(err)
        })
    }
  }
})
