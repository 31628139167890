<template>
  <div>
    <div class="el-section">
      <div class="emeraldraws">
        <svg width="228" height="225" viewBox="0 0 228 225" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M217.17 165.28C239.27 112.672 225.379 74.0922 195.071 36.2143C141.401 -30.4228 25.8528 3.94792 5.0162 66.3764C-15.8204 128.805 32.7983 184.92 67.5259 212.978C102.254 241.036 195.071 217.888 217.17 165.28Z" fill="#677481" fill-opacity="0.05"></path>
          <path d="M163.024 68.2097C163.147 66.4812 161.768 65.0158 160.035 65.0342L74.4184 65.945C72.8191 65.962 71.5216 67.2446 71.4863 68.8436L69.6861 150.252C69.6494 151.915 70.987 153.282 72.6498 153.282H89.7124C90.2923 153.282 90.7171 153.828 90.5744 154.39L85.1064 175.932C84.842 176.973 85.9886 177.801 86.8941 177.222L124.905 152.935C125.048 152.843 125.214 152.795 125.384 152.795H154.219C155.774 152.795 157.065 151.593 157.175 150.042L163.024 68.2097Z" fill="#677481"></path>
          <path d="M111.788 91.279L97.9542 119.481C97.6705 120.083 97.5463 120.751 97.594 121.419C97.6417 122.087 97.8595 122.73 98.2259 123.286C98.5923 123.842 99.0944 124.291 99.6823 124.588C100.27 124.885 100.923 125.019 101.577 124.978L133.252 122.629C133.905 122.573 134.531 122.344 135.069 121.964C135.606 121.584 136.037 121.066 136.317 120.462C136.598 119.858 136.718 119.189 136.667 118.522C136.615 117.854 136.394 117.212 136.025 116.658L118.183 90.8048C117.808 90.2677 117.304 89.8381 116.72 89.5573C116.136 89.2765 115.492 89.154 114.849 89.2017C114.206 89.2493 113.587 89.4655 113.051 89.8294C112.514 90.1933 112.08 90.6925 111.788 91.279V91.279Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
          <path d="M115.714 100.867L116.281 108.512" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
          <path d="M116.848 116.158L116.866 116.157" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
      </div>

    </div>
    <div class="el-chunk">
      <h2 class="emd-text _size_page"><b>Oops</b></h2>
      <p class="emd-text _color_subtle _size_subpage">{{$t('title')}}</p>
    </div>
    <!-- <button class="emd-btn _color_primary" data-test="ctaErrorButton" @click="ctaClicked">
      {{$t('button')}}
    </button> -->
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "title": "Essa página não existe.",
    "button": "Ir para home"
  },
  "en-US": {
    "title": "Page not found",
    "button": "Go to home"
  }
}
</i18n>

<script>
export default {
  name: 'Error404',
  methods: {
    ctaClicked () {
      this.$emit('ctaClicked')
    }
  }
}
</script>
