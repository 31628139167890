<template>
  <form @keyup.enter="sendForm" @submit.prevent="sendForm">
    <div class="eu-flex eu-column eu-row_md">
      <div class="eu-flex-item eu-basis_12">
        <div class="emd-form-group" :class="{'_state_error': fields.name.error}">
          <label class="emd-label" :class="{ '_required': fields.name.validation.required }">{{ $t('field.fullName') }}</label>
          <input class="emd-input" type="text" v-model="fields.name.value" @input="fields.name.error = false" />
          <p v-if="fields.name.error" class="emd-input-feedback">{{ $t(`error.${fields.name.error}`) }}</p>
        </div>
      </div>
    </div>
    <div class="eu-flex eu-column eu-row_md" v-if="showShippingAddress">
      <div class="eu-flex-item eu-basis_12">
        <div class="emd-form-group" :class="{ '_state_error': fields.zip_code.error, '_state_warning': zipCodeNotFound, '_state_success': zipCodeNotFound === false }">
          <label class="emd-label" :class="{ '_required': fields.zip_code.validation.required }">{{ $t('field.zipCode') }}</label>
          <the-mask :masked="false" class="emd-input" mask="#####-###" type="tel" v-model="fields.zip_code.value" @input="searchZipCode" />
          <p v-if="fields.zip_code.error === 'length'" class="emd-input-feedback">{{ $tc(`error.${fields.zip_code.error}`, fields.zip_code.validation.length) }}</p>
          <p v-if="fields.zip_code.error === 'required'" class="emd-input-feedback">{{ $t(`error.${fields.zip_code.error}`) }}</p>
          <a class="emd-input-link eu-display_inline-block" href="http://www.buscacep.correios.com.br/sistemas/buscacep/" target="_blank" rel="noopener noreferrer">Não sei meu CEP</a>
        </div>
      </div>
    </div>
    <div class="eu-flex eu-column eu-row_md" v-if="showShippingAddress">
      <div class="eu-flex-item eu-basis_12">
        <div class="emd-form-group" :class="{ '_state_error': fields.street.error }">
          <label class="emd-label" :class="{ '_required': fields.street.validation.required }">{{ $t('field.street') }}</label>
          <input class="emd-input" type="text" v-model="fields.street.value" @input="fields.street.error = false" />
          <p class="emd-input-feedback">{{ $t('helper.street') }}</p>
          <p v-if="fields.street.error === 'required'" class="emd-input-feedback">{{ $t(`error.${fields.street.error}`) }}</p>
        </div>
      </div>
      <div class="eu-flex-item eu-basis_6_md">
        <div class="emd-form-group" :class="{ '_state_error': fields.number.error }">
          <label class="emd-label" :class="{ '_required': fields.number.validation.required }">{{ $t('field.number') }}</label>
          <input class="emd-input" type="tel" v-model="fields.number.value" @input="fields.number.error = false" />
          <p v-if="fields.number.error === 'required'" class="emd-input-feedback">{{ $t(`error.${fields.number.error}`) }}</p>
        </div>
      </div>
      <div class="eu-flex-item eu-basis_6_md">
        <div class="emd-form-group" :class="{ '_state_error': fields.complement.error }">
          <label class="emd-label" :class="{ '_required': fields.complement.validation.required }">{{ $t('field.complement') }}</label>
          <input class="emd-input" type="text" v-model="fields.complement.value" @input="fields.complement.error = false" />
        </div>
      </div>
      <div class="eu-flex-item eu-basis_12">
        <div class="emd-form-group" :class="{ '_state_error': fields.neighborhood.error }">
          <label class="emd-label" :class="{ '_required': fields.neighborhood.validation.required }">{{ $t('field.neighborhood') }}</label>
          <input class="emd-input" type="text" v-model="fields.neighborhood.value" @input="fields.neighborhood.error = false" />
          <p v-if="fields.neighborhood.error === 'required'" class="emd-input-feedback">{{ $t(`error.${fields.neighborhood.error}`) }}</p>
        </div>
      </div>
    </div>
    <div class="eu-flex eu-column eu-row_md" v-if="showShippingAddress">
      <div class="eu-flex-item eu-basis_12">
        <div class="emd-form-group" :class="{ '_state_error': fields.city.error }">
          <label class="emd-label" :class="{ '_required': fields.city.validation.required }">{{ $t('field.city') }}</label>
          <input class="emd-input" type="text" v-model="fields.city.value" @input="fields.city.error = false" />
          <p v-if="fields.city.error === 'required'" class="emd-input-feedback">{{ $t(`error.${fields.city.error}`) }}</p>
        </div>
        <div class="emd-form-group" :class="{ '_state_error': fields.state.error }">
          <label class="emd-label" :class="{ '_required': fields.state.validation.required }">{{ $t('field.state') }}</label>
          <input class="emd-input" v-mask="['SS']" type="text" placeholder="UF" v-model="fields.state.value" @input="fields.state.error = false" />
          <p v-if="fields.state.error === 'length'" class="emd-input-feedback">{{ $tc(`error.${fields.state.error}`, fields.state.validation.length) }}</p>
          <p v-if="fields.state.error === 'required'" class="emd-input-feedback">{{ $t(`error.${fields.state.error}`) }}</p>
        </div>
      </div>
    </div>
  </form>
</template>

<i18n>
{
  "pt-BR": {
    "shipping": "Entrega",
    "address": "Endereço",
    "recipient": "Destinatário",
    "billing": "Cobrança",
    "zipCodeUnknown": "Não sei meu CEP",
    "billingEqualShipping": "O endereço de cobrança é igual ao de entrega",
    "field": {
      "fullName": "Nome Completo",
      "zipCode": "CEP",
      "street": "Logradouro",
      "number": "Número",
      "complement": "Complemento",
      "neighborhood": "Bairro",
      "line1": "Linha 1",
      "line2": "Linha 2",
      "city": "Cidade",
      "state": "Estado",
      "country": "País"
    },
    "helper": {
      "street": "digite a rua, avenida ou similar",
      "line1": "digite seu logradouro, número e bairro",
      "line2": "digite o complemento do seu endereço"
    },
    "error": {
      "required": "esse campo é obrigatório",
      "zipCodeNotFound": "CEP não encontrado",
      "length": "preencha com 1 caracter | preencha com {count} caracteres"
    }
  },
  "en-US": {
    "shipping": "Shipping",
    "address": "Address",
    "recipient": "Recipient",
    "billing": "Billing",
    "zipCodeUnknown": "I don't know my Postal Code",
    "billingEqualShipping": "Use same Billing Address",
    "field": {
      "fullName": "Full Name",
      "zipCode": "Zip code",
      "street": "Street",
      "number": "Number",
      "complement": "Complement",
      "neighborhood": "Neighborhood",
      "line1": "Line 1",
      "line2": "Line 2",
      "city": "City",
      "state": "State",
      "country": "Country"
    },
    "helper": {
      "street": "enter your street, avenue or similar",
      "line1": "enter your street, number and neighborhood",
      "line2": "enter your address complements"
    },
    "error": {
      "required": "this field is required",
      "zipCodeNotFound": "Zipcode not found",
      "length": "fill in with 1 character | fill in with {count} characters"
    }
  }
}
</i18n>

<script>
import { TheMask } from 'vue-the-mask'
import { isEmpty } from '@/utils'

export default {
  name: 'AppShipping',
  components: {
    TheMask
  },
  data () {
    return {
      zipCodeNotFound: null,
      canSearchZipcode: true
    }
  },
  computed: {
    fields: {
      get () {
        return this.$store.getters.shipping
      }
    },
    showShippingAddress () {
      return this.$store.getters.showShippingAddress
    }
  },
  created () {
    if (!isEmpty(this.fields.zip_code.value)) {
      this.canSearchZipcode = false
    }
  },
  methods: {
    searchZipCode (e) {
      Object.keys(this.fields).forEach((key) => {
        this.fields[key].error = false
      })
      this.zipCodeNotFound = null
      this.fetchZipCode(e)
    },
    sendForm (e) {
      this.$emit('next')
    },
    fetchZipCode (zipCode) {
      if (zipCode && zipCode.length === 8 && this.canSearchZipcode) {
        var params = {
          zipCode: zipCode
        }
        this.$store.dispatch('GET_ZIPCODE', params)
          .then((response) => {
            this.canSearchZipcode = true
            this.zipCodeNotFound = false
            this.fields.street.value = response.street
            this.fields.number.value = ''
            this.fields.complement.value = ''
            this.fields.neighborhood.value = response.district
            this.fields.city.value = response.city
            this.fields.state.value = response.state
          })
          .catch(() => {
            this.canSearchZipcode = true
            this.fields.street.value = ''
            this.fields.number.value = ''
            this.fields.complement.value = ''
            this.fields.neighborhood.value = ''
            this.fields.city.value = ''
            this.fields.state.value = ''
            this.zipCodeNotFound = true
          })
      } else {
        this.canSearchZipcode = true
      }
    }
  }
}
</script>
