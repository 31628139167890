<template>
  <div class="emd-container eu-display_flex eu-row eu-column_sm eu-justify_space-between">
    <div class="emd-image-card eu-pl_2_mobile eu-py_2_mobile eu-pa_3_sm">
      <img v-if="product.display_image" :src="product.display_image" :alt="product.name" :title="product.name" :id="product.id+'_prod'" @error="onImgError(product)" />
      <img v-else :src="empty_bag" :alt="product.name" :title="product.name" />
    </div>
    <div class="emd-container__content eu-display_flex eu-column eu-grow_1 eu-justify_space-between">
      <div class="emd-text-container">
        <p class="emd-text _type_caption eu-pb_1">{{ product.name }}</p>
        <div
          class="emd-text _color_subtle _size_tiny eu-mb_3 eu-height-overflow"
        >{{ product.description }}</div>
      </div>
      <div class="eu-flex eu-align-items_center">
        <div class="eu-flex-item eu-basis_5">
          <p class="emd-text _color_primary _size_small _type_caption">
            R$
            <span class="emd-text _size_section eu-ml_1">{{ product.price | currency }}</span>
          </p>
        </div>
        <div class="eu-flex-item eu-align_right eu-basis_7">
          <button class="emd-btn _color_primary" @click="addToCart(product)">
            <i class="uil uil-shopping-cart" />&nbsp;Adicionar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmdCard',
  props: {
    product: Object
  },
  data () {
    return {
      empty_bag: `${process.env.VUE_APP_CDN_URL}/assets/images/storefront/empty-bag-sm.svg`,
      imgLoaded: false
    }
  },
  methods: {
    onImgError (product) {
      const imgTag = document.getElementById(`${product.id}_prod`)
      imgTag.src = this.empty_bag
    },
    addToCart (product) {
      const data = {
        id: product.id,
        name: product.name,
        quantity: 1,
        price: product.price,
        display_image: product.display_image,
        shippable: product.shippable
      }
      this.$store.dispatch('ADD_ITEM_CART', data).then(() => {
        this.$store.dispatch('UPDATE_TOOLTIP_IS_OPEN', true)
        setTimeout(
          () => this.$store.dispatch('UPDATE_TOOLTIP_IS_OPEN', false),
          3500
        )
      })
    }
  }
}
</script>

<style lang="css" scoped>
.emd-image-card {
  width: 78px;
}
.emd-container__content {
  width: calc(100% - 78px) !important;
}

@media only screen and (min-width: 544px) {
  .emd-image-card {
    width: auto;
    margin: 0 auto;
    height: 200px;
    display: flex;
    align-items: center;
  }

  .emd-image-card img {
    max-height: 180px;
  }

  .emd-container__content {
    width: 100% !important;
  }

  .eu-height-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* number of lines to show */
  }

  .emd-container {
    position: relative;
    opacity: 1;
    transition: 0.5s ease;
    backface-visibility: hidden;
  }
}
</style>
