// import { StorefrontInstance, catchError } from '@/store'
import { hasError } from '@/utils'

const shipping = {
  state: {
    hasError: false,
    fields: {
      name: {
        value: null,
        validation: {
          required: true
        },
        error: false
      },
      zip_code: {
        value: null,
        validation: {
          required: true,
          length: 8
        },
        error: false
      },
      street: {
        value: null,
        validation: {
          required: true
        },
        error: false
      },
      number: {
        value: null,
        validation: {
          required: true
        },
        error: false
      },
      complement: {
        value: null,
        validation: {
          required: false
        },
        error: false
      },
      neighborhood: {
        value: null,
        validation: {
          required: true
        },
        error: false
      },
      city: {
        value: null,
        validation: {
          required: true
        },
        error: false
      },
      state: {
        value: null,
        validation: {
          required: true,
          length: 2
        },
        error: false
      },
      country: {
        value: 'BR',
        validation: {
          required: true,
          length: 2
        },
        error: false
      }
    },
    shippingEnabled: true
  },
  getters: {
    shipping (state) {
      return state.fields
    },
    hasError (state) {
      return state.hasError
    },
    showShippingAddress (state) {
      return state.shippingEnabled
    }
  },
  mutations: {},
  actions: {
    'EMPTY_ERRORS' (context, params = {}) {
      context.state.hasError = false
      const fields = context.state.fields
      Object.keys(fields).forEach((key) => {
        context.state.fields[key].error = false
      })
    },
    'VERIFY_FORM' (context, params = {}) {
      context.state.hasError = false
      const fields = context.state.fields
      Object.keys(fields).forEach((key) => {
        context.state.fields[key].error = hasError(fields[key])
        context.state.hasError = (context.state.hasError || !!context.state.fields[key].error)
      })
    },
    'VERIFY_SHIPPING_ENABLED' (context) {
      const cartWithShippable = context.rootState.cart.list.length && context.rootState.cart.list.filter(item => item.shippable === true).length > 0
      const deliveryEnabled = context.rootState.storeData.delivery ? context.rootState.storeData.delivery.enabled : false
      context.state.shippingEnabled = cartWithShippable && deliveryEnabled
      context.dispatch('CHANGE_REQUIRED_FIELDS', context.state.shippingEnabled)
    },
    'CHANGE_REQUIRED_FIELDS' (context, value) {
      context.state.fields.zip_code.validation.required = value
      context.state.fields.street.validation.required = value
      context.state.fields.number.validation.required = value
      context.state.fields.neighborhood.validation.required = value
      context.state.fields.city.validation.required = value
      context.state.fields.state.validation.required = value
      context.state.fields.country.validation.required = value
    }
  }
}

export default shipping
