import { StorefrontInstance, catchError } from '@/store'
import router from '@/router'

const products = {
  state: {
    categories: null,
    loadingProducts: false,
    noProducts: false,
    products: null,
    filterParams: {}
  },
  getters: {
    categories (state) {
      return state.categories
    },
    loadingProducts (state) {
      return state.loadingProducts
    },
    noProducts (state) {
      return state.noProducts
    },
    products (state) {
      return state.products
    },
    filterParams (state) {
      return state.filterParams
    }
  },
  mutations: {
    setProducts (state, value) {
      state.products = value
    },
    setCategories (state, value) {
      state.categories = value
    },
    setLoadingProducts (state, value) {
      state.loadingProducts = value
    },
    setNoProducts (state, value) {
      state.noProducts = value
    },
    setFilterParams (state, value) {
      state.filterParams = value
    }
  },
  actions: {
    'GET_PRODUCTS' (context, params = {}) {
      params.status = 'active'
      context.dispatch('SET_LOADING_PRODUCTS', true)
      context.dispatch('SET_NO_PRODUCTS', false)

      const storeId = context.getters.storeData.id
      return StorefrontInstance.product.getAll(storeId, params)
        .then((res) => {
          context.commit('setProducts', res)
          context.dispatch('GET_CART')
          if (!res.items.length) context.dispatch('SET_NO_PRODUCTS', true)
          context.dispatch('SET_LOADING_PRODUCTS', false)
          router.replace({ query: params }).catch((err) => err)
          return Promise.resolve(res)
        })
        .catch((err) => catchError(err, context))
    },
    'GET_CATEGORIES' (context, params = {}) {
      const storeId = context.getters.storeData.id
      return StorefrontInstance.category.getAll(storeId)
        .then((res) => {
          context.commit('setCategories', res)
          return Promise.resolve(res)
        })
        .catch((err) => catchError(err, context))
    },
    'SET_LOADING_PRODUCTS' (context, value) {
      context.commit('setLoadingProducts', value)
    },
    'SET_NO_PRODUCTS' (context, value) {
      context.commit('setNoProducts', value)
    },
    'SET_FILTER_PARAMS' (context, value) {
      const newObject = { ...context.state.filterParams, ...value }

      if (Object.prototype.hasOwnProperty.call(value, 'keywords') && value.keywords === null) delete newObject.keywords

      if (value.keywords) {
        newObject.keywords = value.keywords
        delete newObject.page
        delete newObject.size
      }

      if (Object.keys(value).length === 0) {
        newObject.page = 1
        delete newObject.sort_field
        delete newObject.sort_mode
      }

      if (value.sort_field || value.sort_mode) newObject.page = 1

      const newValue = Object.assign({}, newObject)
      context.commit('setFilterParams', newValue)
      context.dispatch('GET_PRODUCTS', newValue)
    }
  }
}

export default products
