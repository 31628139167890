<template>
  <div class="el-storefront__main__content">
    <div v-if="!noProducts" class="eu-pt_4 eu-pb_5">
      <div class="eu-flex eu-justify_space-between">
        <app-input-search />
        <app-ordination-select />
      </div>
    </div>

    <app-loader v-if="loadingProducts" />

    <template v-else>
      <div v-if="noProducts">
        <p
          class="emd-text _color_nonessential _size_subpage eu-align_center"
        >Ops, essa loja ainda não tem produtos.</p>
        <div class="eu-pt_4 eu-pb_5">
          <p class="emd-skeleton-text _color_lvl_4 _size_tiny"></p>
        </div>
        <div class="eu-mb_4">
          <div class="eu-flex eu-justify_space-between eu-align-items_center">
            <div class="eu-flex-item eu-basis_12_mobile eu-align_center eu-align_left_sm">
              <p class="emd-skeleton-text _color_lvl_4 _size_tiny"></p>
            </div>
            <div class="eu-flex-item eu-align_center eu-align_right_sm eu-mt_4 eu-no-margin_sm">
              <div class="emd-skeleton-btn _color_lvl_4"></div>
              <div class="emd-skeleton-btn _color_lvl_4"></div>
            </div>
          </div>
        </div>

        <div class="el-grid-list _auto-fill _size_small">
          <app-card-empty-space />
          <app-card-empty-space />
          <app-card-empty-space />
          <app-card-empty-space />
          <app-card-empty-space />
          <app-card-empty-space />
        </div>
      </div>

      <div v-if="!noProducts">
        <app-empty-search v-if="emptySearch" />
        <div class="eu-display_flex eu-column_sm eu-wrap_reverse_mobile">
          <app-pagination v-if="products && !emptySearch" :data="products" :action="paginationAction" />

          <div class="el-grid-list _auto-fill _size_small eu-fade-in eu-width_100" v-if="products.items">
            <emd-card v-for="product in products.items" :key="product.id" :product="product" />
          </div>
        </div>
      </div>
    </template>

    <app-footer />
  </div>
</template>

<script>
import EmdCard from '@/components/emerald/organisms/EmdCard'
import AppInputSearch from './AppInputSearch'
import AppOrdinationSelect from './AppOrdinationSelect'
import AppCardEmptySpace from './AppCardEmptySpace'
import AppEmptySearch from './AppEmptySearch'
import AppPagination from './AppPagination'
import AppLoader from './AppLoader'
import AppFooter from './AppFooter'

export default {
  name: 'AppMainContent',
  components: {
    EmdCard,
    AppInputSearch,
    AppPagination,
    AppOrdinationSelect,
    AppCardEmptySpace,
    AppEmptySearch,
    AppLoader,
    AppFooter
  },
  computed: {
    products () {
      return this.$store.getters.products
    },
    loadingProducts () {
      return this.$store.getters.loadingProducts
    },
    noProducts () {
      return this.$store.getters.noProducts && (
        !Object.prototype.hasOwnProperty.call(this.$route.query, 'keywords') &&
        !Object.prototype.hasOwnProperty.call(this.$route.query, 'sort_mode') &&
        !Object.prototype.hasOwnProperty.call(this.$route.query, 'sort_field')
      )
    },
    emptySearch () {
      return this.$store.getters.noProducts && Object.prototype.hasOwnProperty.call(this.$route.query, 'keywords')
    },
    paginationAction () {
      return {
        name: 'SET_FILTER_PARAMS',
        param: {}
      }
    }
  }
}
</script>
