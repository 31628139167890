<template>
  <div id="app">
    <global-error v-if="hasError" />
    <template v-else>
      <router-view/>
    </template>
  </div>
</template>
<script>
import GlobalError from '@/components/project/globals/GlobalError'

export default {
  name: 'App',
  components: {
    GlobalError
  },
  computed: {
    hasError () {
      return this.$store.state.globalError
    }
  }
}
</script>
