<template>
  <main class="stone">
    <global-loader v-if="globalLoading" />
    <div v-if="!globalLoading" class="el-storefront">
      <emd-aside-area />
      <app-main @openCart="openModal" />
      <app-modal :cartOpenned="modalOpenned" @closeCart="closeModal" />
    </div>
  </main>
</template>

<style>
.emd-btn,
a.emd-btn {
  letter-spacing: normal;
}
</style>

<script>
import EmdAsideArea from '@/components/emerald/organisms/EmdAsideArea'
import AppMain from '@/components/project/ui/AppMain'
import AppModal from '@/components/project/ui/modal/AppModal'
import GlobalLoader from '@/components/project/globals/GlobalLoader.vue'

export default {
  name: 'home',
  components: {
    EmdAsideArea,
    AppMain,
    AppModal,
    GlobalLoader
  },
  data () {
    return {
      modalOpenned: false
    }
  },
  computed: {
    globalLoading () {
      return this.$store.getters.globalLoading
    }
  },
  methods: {
    openModal () {
      this.modalOpenned = true
      document.body.style.overflow = 'hidden'
    },
    closeModal () {
      this.modalOpenned = false
      document.body.style.overflow = 'auto'
    }
  }
}
</script>
