/* eslint-disable */
import { isEmpty } from '@/utils'

const theme = {
  mutations: {
    setAccountTheme (state, color) {
      if (color) {
        var css = ''
        var head = document.querySelector('head')
        var style = document.createElement('style')
        var primaryColor = removeHashFromHex(color)
        if (primaryColor) {
          /* PRIMARY COLOR */
          const primaryTextColor = setupTextColor(primaryColor, 160)
          // clarear cor no hover caso textColor seja branca
          const primaryHoverIndex = primaryTextColor === 'FFFFFF' ? 12 : -12
          const primaryColorHover = setupHoverColor(primaryColor, primaryHoverIndex)
          if (!isEmpty(primaryColor)) {
            css += buildPrimaryColor(primaryColor, primaryColorHover, primaryTextColor)
          }
        }
        style.type = 'text/css'
        if (style.styleSheet) {
          style.styleSheet.cssText = css
        } else {
          style.appendChild(document.createTextNode(css))
        }
        head.appendChild(style)
      }
    }
  }
}

function buildPrimaryColor (color, hover, text) {
  // add new color
  const element = `
    .stone { --brand--le--primary-color--base: #${color}; --brand--le--primary-color--shade: #${color}; }
    ._mode_light .el-storefront__main__header, .el-storefront__main__header { background-color: #${color}; }
    ._mode_light .el-storefront__main__header > .emd-text, .el-storefront__main__header > .emd-text { color: #${text}; }
    ._mode_light .emd-btn._color_primary, .emd-btn._color_primary { background-color: #${color}; color: #${text}; border-color: #${color}; }
    ._mode_light .emd-btn._color_primary:focus, ._mode_light .emd-btn._color_primary:hover, .emd-btn._color_primary:focus, .emd-btn._color_primary:hover { background-color: #${hover}; border-color: #${hover}; color: #${text}; }
    .emd-text._color_primary, .mode_light .emd-text._color_primary { color: #${color}; }
    ._mode_light .emd-btn._color_primary._type_link, .emd-btn._color_primary._type_link { color: #${color}; background-color: transparent; }
    ._mode_light .emd-btn._color_primary._type_link:focus, .emd-btn._color_primary._type_link:focus, ._mode_light .emd-btn._color_primary._type_link:hover, .emd-btn._color_primary._type_link:hover { color: #${color}; background-color: transparent; }
    ._mode_light .emd-btn._color_primary._type_outline, .emd-btn._color_primary._type_outline { color: #${color}; border-color: #${color}; }
    ._mode_light .emd-btn._color_primary._type_outline:focus, ._mode_light .emd-btn._color_primary._type_outline:hover, .emd-btn._color_primary._type_outline:focus, .emd-btn._color_primary._type_outline:hover { color: #${color}; border-color: #${color}; background-color: rgba(0,0,0,.05); }
    ._mode_light .emd-btn._color_primary._type_text, .emd-btn._color_primary._type_text { color: #${color}; background-color: transparent; }
    ._mode_light .emd-btn._color_primary._type_text:focus, .emd-btn._color_primary._type_text:focus, ._mode_light .emd-btn._color_primary._type_text:hover, .emd-btn._color_primary._type_text:hover { color: #${color}; background-color: transparent; background-color: rgba(0,0,0,.05); }
  `
  return element
}

function removeHashFromHex (color) {
  if (color && color.startsWith('#')) {
      color = color.substring(1)
  }
  return color
}

function setupHoverColor (color, percent) {
  if (color) {
    var num = parseInt(color,16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    B = (num >> 8 & 0x00FF) + amt,
    G = (num & 0x0000FF) + amt;
    return (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (B<255?B<1?0:B:255)*0x100 + (G<255?G<1?0:G:255)).toString(16).slice(1)
  }
  return color
}
// check color luminosity
function setupTextColor (color, limit) {
  if (color) {
      var rgb = getRGB(color)
      var luma = 0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b
      if (luma < limit) {
          return 'FFFFFF'
      } else {
          return '000000'
      }
  } else {
      return '000000'
  }
}

function getRGB(color) {
  if (color) {
    var rgb = parseInt(color, 16) // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff // extract red
    var g = (rgb >> 8) & 0xff // extract green
    var b = (rgb >> 0) & 0xff // extract blue
    return { 'r': r, 'g': g, 'b': b }
  }
  return color
}

export default theme
