<template>
  <div class="el-storefront__menu">
    <div class="eu-flex eu-row eu-column_md eu-align-items_center">
      <div class="eu-flex-item eu-shrink_1 eu-mb_4_md">
        <div class="el-storefront__menu__img">
           <img v-if="storeData.logo_image_url" :src="storeData.logo_image_url" :alt="storeData.name" :title="storeData.name" id="logoStore" @error="onImgError" />
          <img v-else :src="no_picture" :alt="storeData.name" />
        </div>
      </div>
      <div class="eu-flex-item">
        <p class="emd-text _type_label _size_tiny">Loja Virtual</p>
        <p class="emd-text _size_page">{{ storeData.name }}</p>
      </div>
    </div>
    <div class="eu-mt_4">
      <p class="eu-mb_4">{{ storeData.about }}</p>
      <div class="eu-display_flex eu-justify_space-between">
        <a :href="urlWhatsapp" target="_blank" class="emd-btn _type_outline _color_primary _size_big" v-if="storeData.social && storeData.social.whatsapp">
          <i class="uil uil-whatsapp" />
          <span class="eu-ml_2">{{ storeData.social.whatsapp | phone }}</span>
        </a>
        <button class="emd-btn _type_text _color_nonessential _size_big eu-display_none_md" @click="toggleInfo()">
          <i class="uil uil-info-circle" />
        </button>
      </div>
    </div>
    <div class="el-section emd-text _size_small eu-display_block_md" v-bind:class="{'eu-display_none': hideInfo}">
      <p class="emd-text _type_caption eu-mb_1">Horário de Funcionamento</p>
      <div class="eu-mb_4">
        <div v-for="(day, key) in days" class="eu-display_flex eu-wrap" v-bind:key="key">
          <template v-if="storeData.working_hours[key]">
            <p class="eu-basis_6">{{ day }}</p>
            <p class="eu-basis_6 eu-align_right">{{ storeData.working_hours[key].description }}</p>
          </template>
        </div>
      </div>
      <div v-if="storeData.delivery">
        <p class="emd-text _type_caption eu-mb_1">Entregas</p>
        <p>
          <span v-for="(location, index) in regions" v-bind:key="index">
            {{ location }}<template v-if="index < regions.length - 1">,</template>
          </span>
          <br />Prazo {{storeData.delivery.estimated_time}}
        </p>
      </div>
      <hr class="emd-divider _weight_strong eu-my_4" />
      <div v-if="storeData.address">
        <p class="emd-text _type_caption eu-mb_1">Onde nos encontrar</p>
        <ul class="emd-list _space_medium">
          <li class="eu-display_flex eu-align-items_center">
            <i class="uil uil-map-marker-alt eu-mr_2 emd-text _color_primary" />
            <div>
              {{storeData.address.street}}, {{storeData.address.number}}, {{storeData.address.complement}}<span v-if="storeData.address.complement">, </span>{{storeData.address.neighborhood}}, {{storeData.address.city}}, {{storeData.address.state}}
            </div>
          </li>
          <li class="eu-display_flex eu-align-items_center" v-if="storeData.social && storeData.social.instagram">
            <a :href="urlInstagram" class="emd-link" target="_blank">
              <i class="uil uil-instagram eu-mr_2 emd-text _color_primary" />
              @{{storeData.social.instagram}}
            </a>
          </li>
          <li class="eu-display_flex eu-align-items_center" v-if="storeData.social && storeData.social.twitter">
            <a :href="urlTwitter" class="emd-link" target="_blank">
              <i class="uil uil-twitter eu-mr_2 emd-text _color_primary" />
              @{{storeData.social.twitter}}
            </a>
          </li>
          <li class="eu-display_flex eu-align-items_center" v-if="storeData.social && storeData.social.facebook">
            <a :href="urlFacebook" class="emd-link" target="_blank">
              <i class="uil uil-facebook eu-mr_2 emd-text _color_primary" />
              /{{storeData.social.facebook}}
            </a>
          </li>
          <li class="eu-display_flex eu-align-items_center" v-if="storeData.social && storeData.social.website">
            <a :href="storeData.social.website" class="emd-link" target="_blank">
              <i class="uil uil-globe eu-mr_2 emd-text _color_primary" />
              {{storeData.social.website}}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmdAsideArea',
  data () {
    return {
      no_picture: `${process.env.VUE_APP_CDN_URL}/assets/images/storefront/no-picture.svg`,
      hideInfo: true,
      days: {
        monday: 'Segunda-feira',
        tuesday: 'Terça-feira',
        wednesday: 'Quarta-feira',
        thursday: 'Quinta-feira',
        friday: 'Sexta-feira',
        saturday: 'Sábado',
        sunday: 'Domingo'
      }
    }
  },
  computed: {
    storeData () {
      return this.$store.getters.storeData
    },
    regions () {
      return this.storeData.delivery ? this.storeData.delivery.accepted_regions : '-'
    },
    urlInstagram () {
      return `https://instagram.com/${this.storeData.social.instagram}`
    },
    urlTwitter () {
      return `https://twitter.com/${this.storeData.social.twitter}`
    },
    urlFacebook () {
      return `https://facebook.com/${this.storeData.social.facebook}`
    },
    urlWhatsapp () {
      return `https://api.whatsapp.com/send?phone=${this.storeData.social.whatsapp}`
    }
  },
  methods: {
    onImgError () {
      const imgTag = document.getElementById('logoStore')
      imgTag.src = this.no_picture
    },
    getImage (image) {
      return require(image)
    },
    toggleInfo () {
      this.hideInfo = !this.hideInfo
    }
  }
}
</script>
