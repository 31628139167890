<template>
  <div class="el-storefront__main">
    <app-main-header />
    <app-main-content />
    <div class="el-storefront__main__fab">
      <div class="emd-tip" :class="{'_active': tipIsOpen}">
        <div class="emd-tip__container">
          <div class="emd-tip__box _arrow_right">
            <h3 class="emd-text _color_primary _type_caption">Produto adicionado!</h3>
            <p class="eu-mt_1">Clique aqui para ir para o pagamento</p>
          </div>
        </div>
      </div>
      <button class="emd-btn _color_primary _size_big eu-ma_3 openCartBtn" @click="openCart">
        <div class="eu-py_1">
          <i style="font-size:30px" class="uil uil-shopping-cart" />
        </div>
      </button>
      <span v-if="quantity > 0" class="emd-badge _color_danger _position_bottom-right _position_top-right_md _type_round _type_contrast">
        {{ quantity }}
      </span>
    </div>
  </div>
</template>

<script>
import AppMainHeader from '@/components/project/ui/AppMainHeader'
import AppMainContent from '@/components/project/ui/AppMainContent'

export default {
  name: 'AppMain',
  components: {
    AppMainHeader,
    AppMainContent
  },
  computed: {
    cart () {
      return this.$store.getters.cart
    },
    quantity () {
      var totalQuantity = 0
      this.cart.forEach((element, index) => {
        totalQuantity = totalQuantity + element.quantity
      })
      return totalQuantity
    },
    tipIsOpen () {
      return this.$store.getters.tooltipIsOpen
    }
  },
  methods: {
    openCart () {
      this.$emit('openCart')
    }
  }
}
</script>
