<template>
  <div class="el-storefront__main__header">
    <div class="emd-text _color_clear">
      <p class="emd-text _type_label _size_tiny">Loja Virtual</p>
      <p class="emd-text _size_page">{{ storeName }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppMainHeader',
  computed: {
    storeName () {
      return this.$store.getters.storeData.name
    }
  }
}
</script>
